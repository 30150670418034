<template>
  <transition name="fade">
    <div class="fd-banner" v-show="isShown">
      <div class="row align-items-center w-100">
        <div :class="dismissable ? 'col-12 sm-col-10 md-col-11' : 'col-12'">
          <slot></slot>
        </div>
        <div
          v-if="dismissable"
          class="
            col-12
            sm-col-2
            md-col-1
            mt-2
            sm-mt-0
            d-flex
            justify-content-end
          "
        >
          <button type="button" class="dismiss-button ml-2" @click="dismiss">
            Dismiss
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    dismissable: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      isShown: true
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    dismiss() {
      this.isShown = false;
    }
  }
};
</script>

<style lang="scss">
.fd-banner {
  padding: 8px 16px;
  border-radius: 4px;
  .dismiss-button {
    background: #ffffff15;
    color: inherit;
    &:hover {
      background: #ffffff35;
    }
    i {
      font-size: 12px;
    }
  }
}
</style>
